import React from "react";
import { Button, Flex, Image, Link } from "@chakra-ui/react";

const HeaderLinkButtons = ({ links }) => {
    return (
        <Flex direction="row" align="center" mt="0.5%" ml="12px">
            {links
                ? links.map((item, idx) => {
                      return (
                          <Flex
                              align="center"
                              justify="center"
                              borderRadius="2px"
                              key={idx}
                              ml="8px"
                          >
                              <Link href={item.link} isExternal>
                                  <Button
                                      variant="link"
                                      size="md"
                                      width="32px"
                                      height="32px"
                                      bg="#EDEDED"
                                      borderRadius="2px"
                                      _hover={{ bg: "pink" }}
                                  >
                                      <Image src={item.icon} />
                                  </Button>
                              </Link>
                          </Flex>
                      );
                  })
                : null}
        </Flex>
    );
};

export default HeaderLinkButtons;
