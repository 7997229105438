import React from "react";
import { Flex, Text } from "@chakra-ui/react";
import HeaderLinkButtons from "./header-link-buttons";

const WorksSectionHeader = ({ headerText, links }) => {
    return (
        <Flex className="section-header" direction="row" align="center">
            <Text
                className="works-header"
                fontSize={{
                    base: "48px",
                    sm: "24px",
                    md: "36px",
                    lg: "48px",
                }}
            >
                {headerText}
            </Text>
            <HeaderLinkButtons links={links ?? []} />
        </Flex>
    );
};

export default WorksSectionHeader;
