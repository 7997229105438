import "../../App.css";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
} from "react-router-dom";
import LinksPage from "../../pages/links-page/links-page";
import AboutPage from "../../pages/about-page/about-page";
import WorksPage from "../../pages/works-page/works-page";
import WorksImagePage from "../../pages/wokrs-image-page/works-image-page";

function App() {
    return (
        <div className="app" p={{ lg: "0 125px", sm: "0 50px" }}>
            <Router>
                <Routes>
                    <Route
                        path="/"
                        element={<Navigate to="/works" replace />}
                    />
                    <Route path={"/linktree"} element={<LinksPage />}></Route>
                    <Route path={"/about"} element={<AboutPage />}></Route>
                    <Route path={"/works"} element={<WorksPage />}></Route>
                    <Route path={"/works/:id"} element={<WorksImagePage />} />
                    <Route
                        path={"*"}
                        element={<Navigate to="/works" replace />}
                    />
                </Routes>
            </Router>
        </div>
    );
}

export default App;
