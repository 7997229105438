import React, { lazy, Suspense } from "react";
import {
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    Flex,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import "./image-modal.css";

function ImageModal({
    isOpen,
    onClose,
    imageSrc,
    imageRelativeWidth,
    imageRelativeHeight,
}) {
    const LazyImage = lazy(() =>
        import("@chakra-ui/react").then((module) => ({
            default: module.Image,
        })),
    );

    return (
        <>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                size={{ sm: "xs", md: "sm", lg: "sm", base: "sm" }}
                closeOnOverlayClick={true}
            >
                <ModalOverlay backdropFilter="blur(4px)" />
                <ModalContent padding="0" position="relative">
                    <ModalBody
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        className="asd"
                        padding="0"
                        position="relative"
                        onClick={onClose}
                    >
                        <Suspense fallback={null}>
                            <Flex justify="center" align="center">
                                <LazyImage
                                    src={imageSrc}
                                    objectFit="cover"
                                    maxHeight={imageRelativeHeight}
                                    maxWidth={imageRelativeWidth}
                                />
                            </Flex>
                        </Suspense>
                        <CloseIcon
                            position="absolute"
                            top="0"
                            right="-450"
                            _hover={{ color: "pink" }}
                            cursor="pointer"
                            w="18px"
                            h="18px"
                            onClick={onClose}
                        />
                        {/*</Flex>*/}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
}

export default ImageModal;
