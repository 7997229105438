import React from "react";
import WorksTableSection from "./works-table-section";
import { Flex } from "@chakra-ui/react";

const WorksTable = ({ items }) => {
    return (
        <Flex direction="column">
            {items.map((item, idx) => {
                return (
                    <WorksTableSection
                        key={`${item.headerText}-${idx}`}
                        items={item.items}
                        links={item.links}
                        headerText={item.headerText}
                    />
                );
            })}
        </Flex>
    );
};

export default WorksTable;
