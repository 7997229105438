import React from "react";
import AppFooter from "../../components/UI/app-footer";
import AppHeader from "../../components/UI/app-header";
import WorksTable from "../../components/works/works-table";
import { data } from "../../store/data";
import { Box } from "@chakra-ui/react";

const WorksPage = () => {
    return (
        <>
            <AppHeader />
            <Box mt={{ base: "90px", sm: "30px", md: "60px", lg: "90px" }}>
                <WorksTable items={data} />
            </Box>
            <AppFooter />
        </>
    );
};

export default WorksPage;
