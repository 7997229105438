import React from "react";
import App from "./app";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "../../utils/theme";
import "@fontsource/inter";

const AppWrapper = () => {
    return (
        <ChakraProvider theme={theme}>
            <App />
        </ChakraProvider>
    );
};

export default AppWrapper;
