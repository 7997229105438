import React from "react";
import { Box, Text } from "@chakra-ui/react";

const AppFooter = () => {
    return (
        <Box mt="61px" mb="61px">
            <Text
                textAlign="center"
                fontWeight="400"
                fontSize="14px"
                lineHeight="150%"
                color="gray"
            >
                mulan.art
            </Text>
        </Box>
    );
};

export default AppFooter;
