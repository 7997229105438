import React from "react";
import AppHeader from "../../components/UI/app-header";
import { Box, Flex, Text, Image, Link, Stack } from "@chakra-ui/react";
import AppFooter from "../../components/UI/app-footer";

const AboutPage = () => {
    return (
        <>
            <AppHeader />
            <Box>
                <Flex
                    direction={{ lg: "row", sm: "column" }}
                    mt={{ base: "100px", sm: "50px", md: "75px", lg: "100px" }}
                    justify="space-between"
                    align={{ base: "flex-start" }}
                >
                    <Box mr="20px">
                        <Image
                            src="/images/about-page-img.webp"
                            alt="h"
                            minWidth={{
                                base: "610px",
                                sm: "280px",
                                lg: "610px",
                            }}
                            minHeight={{
                                base: "910px",
                                sm: "280px",
                                lg: "910px",
                            }}
                        />
                    </Box>
                    <Box maxWidth={{ lg: "50%", sm: "90%" }} textAlign="left">
                        <Text
                            fontWeight="600"
                            fontSize={{
                                base: "16px",
                                sm: "12px",
                                md: "14px",
                                lg: "16px",
                            }}
                            lineHeight="20px"
                            textTransform="uppercase"
                            letterSpacing="0.1em"
                            mt={{ sm: "30px", lg: "0" }}
                        >
                            Hello,
                        </Text>
                        <Stack spacing={4}>
                            <Text
                                fontWeight="400"
                                lineHeight="20px"
                                letterSpacing="0.1em"
                                mt="40px"
                                fontSize={{
                                    base: "16px",
                                    sm: "13px",
                                    md: "14px",
                                    lg: "16px",
                                }}
                            >
                                I'm Nicole Liu, but you may know me better as
                                Mulan. That's a nickname I've held since
                                childhood - a nod to the tomboyish leader I
                                always was among other kids.
                            </Text>
                            <Text
                                fontWeight="400"
                                fontSize={{
                                    base: "16px",
                                    sm: "13px",
                                    md: "14px",
                                    lg: "16px",
                                }}
                                lineHeight="20px"
                                letterSpacing="0.1em"
                                mt="40px"
                            >
                                Life has been a wild ride. I was born to a
                                Chinese family that couldn't keep me, adopted
                                into wealth, and then left to navigate through a
                                maze of changing relatives when that family fell
                                apart. Yes, it's been a rollercoaster. But you
                                know what? I've found my own way to make sense
                                of it all.
                            </Text>
                            <Text
                                fontWeight="400"
                                fontSize={{
                                    base: "16px",
                                    sm: "13px",
                                    md: "14px",
                                    lg: "16px",
                                }}
                                lineHeight="20px"
                                letterSpacing="0.1em"
                                mt="40px"
                            >
                                Art. That's my refuge, my solace. It's the way I
                                process the tough stuff, the way I celebrate the
                                good, and the method I use to explore everything
                                in between. The world can be full of suffering
                                and misery, I won't deny that. Yet, I've found
                                that there's also beauty to be discovered, and
                                meaning to be made.
                            </Text>
                            <Text
                                fontWeight="400"
                                fontSize={{
                                    base: "16px",
                                    sm: "13px",
                                    md: "14px",
                                    lg: "16px",
                                }}
                                lineHeight="20px"
                                letterSpacing="0.1em"
                                mt="40px"
                            >
                                When I create, I'm searching for that beauty.
                                I'm sifting through the chaos, the pain, the joy
                                - all the raw materials of life - to find those
                                nuggets of something...breathtaking. Art doesn't
                                just decorate space; it elevates life. It adds
                                depth to our understanding, color to our
                                emotions, and substance to our experiences.
                            </Text>
                            <Text
                                fontWeight="400"
                                fontSize={{
                                    base: "16px",
                                    sm: "13px",
                                    md: "14px",
                                    lg: "16px",
                                }}
                                lineHeight="20px"
                                letterSpacing="0.1em"
                                mt="40px"
                            >
                                I'm also intrigued by the world of crypto
                                culture and the idea of decentralization.
                                There's something empowering about the concept
                                of digital assets and their potential to shift
                                how we perceive value and ownership. I believe
                                the future of art is inextricably tied to these
                                digital landscapes, and I am excited to be a
                                part of that journey.
                            </Text>
                            <Text
                                fontWeight="400"
                                fontSize={{
                                    base: "16px",
                                    sm: "13px",
                                    md: "14px",
                                    lg: "16px",
                                }}
                                lineHeight="20px"
                                letterSpacing="0.1em"
                                mt="40px"
                            >
                                So, welcome to my corner of the world. It's a
                                patchwork of thoughts, emotions, and experiences
                                - all translated into color, lines, and
                                textures. Here, you'll find pieces of me, of my
                                story, of my ongoing quest for beauty in life's
                                intricate tapestry.
                            </Text>
                            <Text
                                fontWeight="400"
                                fontSize={{
                                    base: "16px",
                                    sm: "13px",
                                    md: "14px",
                                    lg: "16px",
                                }}
                                lineHeight="20px"
                                letterSpacing="0.1em"
                                mt="40px"
                            >
                                I hope you'll find something that speaks to you.
                                Something that perhaps mirrors a part of your
                                own journey. Thank you for being here.
                            </Text>
                        </Stack>

                        <Text
                            mt="24px"
                            fontWeight="500"
                            fontSize={{
                                base: "24px",
                                sm: "20px",
                                md: "22px",
                                lg: "24px",
                            }}
                            lineHeight="56px"
                            textTransform="uppercase"
                            letterSpacing="0.1em"
                        >
                            talk to me
                        </Text>
                        <Text
                            mt="6px"
                            fontWeight="400"
                            fontSize={{
                                base: "16px",
                                sm: "13px",
                                md: "14px",
                                lg: "16px",
                            }}
                            lineHeight="20px"
                        >
                            Twitter:{" "}
                            <Text fontWeight="bold" as="span">
                                <Link
                                    href="https://twitter.com/mulan_art"
                                    isExternal
                                >
                                    @mulan.art
                                </Link>
                            </Text>
                        </Text>
                        <Text
                            mt="6px"
                            fontWeight="400"
                            fontSize={{
                                base: "16px",
                                sm: "13px",
                                md: "14px",
                                lg: "16px",
                            }}
                            lineHeight="20px"
                        >
                            Discord:{" "}
                            <Text as="span" fontWeight="bold">
                                <Link
                                    href="https://discord.gg/Gf7sXET4va"
                                    isExternal
                                >
                                    discord.gg/Gf7sXET4va
                                </Link>
                            </Text>
                        </Text>
                    </Box>
                </Flex>
            </Box>
            <AppFooter />
        </>
    );
};

export default AboutPage;
