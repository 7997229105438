import React, { useEffect, useState, lazy, Suspense } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { data } from "../../store/data";
import { Flex } from "@chakra-ui/react";
import AppHeader from "../../components/UI/app-header";
import AppFooter from "../../components/UI/app-footer";
import { CloseIcon } from "@chakra-ui/icons";
import LoadingSpinner from "../../components/UI/loading-spinner";

const LazyImage = lazy(() =>
    import("@chakra-ui/react").then((module) => ({ default: module.Image })),
);

const WorksImagePage = () => {
    const [pageItem, setPageItem] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const path = useParams().id;
    const navigate = useNavigate();

    const navigateToWorksPage = () => {
        navigate("/works");
    };

    const findItem = (query) => {
        return (
            data
                .flatMap((header) => header.items)
                .find(
                    (item) => item.path === query || item.imageName === query,
                ) ?? null
        );
    };

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            const currentPageItem = findItem(path);
            setPageItem(currentPageItem);
            setIsLoading(false);
        }, 500);

        return () => clearTimeout(timeoutId);
    }, [path]);

    return (
        <>
            <AppHeader />
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <Flex
                    mt="61px"
                    align="center"
                    justify="center"
                    position="relative"
                >
                    <Flex align="start">
                        <Suspense fallback={<LoadingSpinner />}>
                            <Flex justify="center" align="center">
                                <LazyImage
                                    src={pageItem?.imageSrc}
                                    objectFit="cover"
                                    maxHeight="650px"
                                    maxWidth="1000px"
                                />
                            </Flex>
                        </Suspense>
                    </Flex>
                    <CloseIcon
                        position="absolute"
                        top="0"
                        right="0"
                        _hover={{ color: "pink" }}
                        cursor="pointer"
                        w={13}
                        h={13}
                        onClick={navigateToWorksPage}
                    />
                </Flex>
            )}
            <AppFooter />
        </>
    );
};

export default WorksImagePage;
