import React, { Suspense, lazy, useEffect, useState } from "react";
import { Text, useDisclosure } from "@chakra-ui/react";

import ImageModal from "../../pages/wokrs-image-page/image-modal";
import { ReactComponent as PlusIcon } from "../../svg/plus.svg";

const WorksTableItem = ({ item }) => {
    const LazyImage = lazy(() =>
        import("@chakra-ui/react").then((module) => ({
            default: module.Image,
        })),
    );

    const [imageSrc, setImageSrc] = useState(null);
    const [imageWidth, setImageWidth] = useState(0);
    const [imageHeight, setImageHeight] = useState(0);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);

    const { isOpen, onOpen, onClose } = useDisclosure();

    useEffect(() => {
        const getImageSize = () => {
            const img = new Image();
            img.src = item.imageSrc;
            img.onload = () => {
                setImageWidth(img.width);
                setImageHeight(img.height);
            };
        };
        getImageSize();
    }, [item.imageSrc]);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
            setScreenHeight(window.innerHeight);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const imageToScreenWidthRatio = imageWidth / screenWidth;
    const imageToScreenHeightRatio = imageHeight / screenHeight;

    const imageRelativeWidth =
        imageToScreenWidthRatio > imageToScreenHeightRatio
            ? screenWidth * 0.9
            : (imageWidth / imageToScreenHeightRatio) * 0.9;

    const imageRelativeHeight =
        imageToScreenWidthRatio > imageToScreenHeightRatio
            ? (imageHeight / imageToScreenWidthRatio) * 0.9
            : screenHeight * 0.9;

    const handleCloseModal = () => {
        onClose();
        setImageSrc(null);
    };

    const handleOpenModal = () => {
        setImageSrc(item.imageSrc);
        onOpen();
    };

    const imageCustomHeight = item.customSize?.height || "300px";
    const itemCustomWidth = item.customSize?.width || "500px";

    return (
        <>
            <button className="mr-[10px] mb-[26px]" onClick={handleOpenModal}>
                {item?.type === "link" ? (
                    <a
                        className="flex flex-col justify-center items-center bg-[#EDEDED] h-full hover:scale-105 transition-all duration-300 ease-in-out"
                        style={{aspectRatio: item.aspectRatio}}
                        href={item.href}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <PlusIcon />
                        see more
                    </a>
                ) : (
                    <div className="flex flex-col">
                        <Suspense fallback={null}>
                            <div style={{ width: "100%", height: "100%" }}>
                                <LazyImage
                                    src={item.imageSrc}
                                    objectFit="cover"
                                    maxHeight={{
                                        base: imageCustomHeight,
                                        sm: "420px",
                                        md: imageCustomHeight,
                                        lg: imageCustomHeight,
                                    }}
                                    height={{ base: "100%" }}
                                    maxWidth={{
                                        base: itemCustomWidth,
                                        sm: "600px",
                                        md: itemCustomWidth,
                                        lg: itemCustomWidth,
                                    }}
                                    _hover={{
                                        transform: "scale(1.05)",
                                        transition:
                                            "transform 0.4s ease-in-out",
                                    }}
                                />
                            </div>
                            {item.imageName ? (
                                <Text
                                    mt="14px"
                                    fontWeight="600"
                                    fontSize="14px"
                                    lineHeight="20px"
                                    textTransform="uppercase"
                                    letterSpacing="0.1em"
                                >
                                    {item.imageName}
                                </Text>
                            ) : null}
                        </Suspense>
                    </div>
                )}
            </button>
            <ImageModal
                isOpen={isOpen}
                onClose={handleCloseModal}
                imageSrc={imageSrc}
                imageRelativeWidth={imageRelativeWidth}
                imageRelativeHeight={imageRelativeHeight}
            />
        </>
    );
};

export default WorksTableItem;
