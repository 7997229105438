import { Spinner, Center, Flex } from "@chakra-ui/react";

const LoadingSpinner = () => {
    return (
        <Flex height="70vh" alignItems="center" justifyContent="center">
            <Center>
                <Spinner size="xl" color="pink" />
            </Center>
        </Flex>
    );
};

export default LoadingSpinner;
