import { extendTheme } from "@chakra-ui/react";

const breakpoints = {
    sm: "320px",
    md: "768px",
    lg: "960px",
    xl: "1200px",
    "2xl": "1536px",
};

const theme = extendTheme({
    fonts: {
        body: "Inter",
    },
    colors: {
        button: {
            black: "#000000",
            white: "#FFFFFF",
            gray: "#6B7280",
            pink: "#E27DB5",
            lightPink: "#FFC8E5",
            whiteGray: "#EDEDED",
        },
    },
    breakpoints,
});

export default theme;
